<!--数字化平台-->
<template>
  <div class="digitalPlatform">
    <div class="flex justify-content-center digitalPlatform-title">
      <kcTitle2 title="小科爱牧O²S智慧养殖大平台" en="Xiao Ke Ai Mu O²S Smart Farming Platform"></kcTitle2>
    </div>
    <div class="digitalPlatform-content flex align-items-center justify-content-between">
      <div class="left-cont">
        <div class="dp-cont-title1">平台介绍</div>
        <div class="dp-cont-title2" style="margin-top: 30px;height: 270px;width: 100%">科创信达致力于打造Only One
          System数字大平台，基于客户的企业商业模式设计与未来战略发展规划，将数字技术贯穿“端到端”的企业经营与管理、养殖生产与管理。将智能装备的采集数据沉淀为企业大数据，充分发挥数据底座+云平台的商业价值。通过数字中台赋能企业实际运营管理，横向打通业务流、纵向贯通战略到执行。通过搭建“1+N+N”的平台架构体系实现“上游一盘货、中游一盘帐、下游一盘棋”的数字大平台布局。
        </div>
        <div>
          <div class="list flex">
            <div class="dp-list-title1 mr10" style="width: 150px">超大型集团</div>
            <div class="dp-list-title1 mr10" style="width: 100px;">MES系统</div>
            <div class="flex align-items-center mr10" style="width: 12px;height: 40px">
              <img src="../../../assets/images/Core/img/icon.png" width="100%">
            </div>
            <div class="dp-cont-title2">打通养殖场与集团管理系统的信息断层</div>
          </div>
          <div class="list flex">
            <div class="dp-list-title1 mr10" style="width: 150px">规模化养殖</div>
            <div class="dp-list-title1 mr10" style="width: 100px;">PaaS系统</div>
            <div class="flex align-items-center mr10" style="width: 12px;height: 40px">
              <img src="../../../assets/images/Core/img/icon.png" width="100%">
            </div>
            <div class="dp-cont-title2">基于现有PaaS系统的定制化开发</div>
          </div>
          <div class="list flex">
            <div class="dp-list-title1 mr10" style="width: 150px">家庭农场</div>
            <div class="dp-list-title1 mr10" style="width: 100px;">SaaS系统</div>
            <div class="flex align-items-center mr10" style="width: 12px;height: 40px">
              <img src="../../../assets/images/Core/img/icon.png" width="100%">
            </div>
            <div class="dp-cont-title2">标准化的物联网软件生产管理软件</div>
          </div>
        </div>
      </div>
      <div style="width: 50%">
        <img src="../../../assets/images/Core/img/computer.png" width="100%">
      </div>
    </div>
    <div class="line"></div>
    <div class="step-content flex justify-content-center flex-warp mt20">
      <div class="dp-cont-title1" style="margin:  40px 0">一个系统·实现生产精准管理</div>
      <div>
        <img src="../../../assets/images/Core/img/step.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "digitalPlatform"
}
</script>

<style lang="scss" scoped>
.digitalPlatform {
  padding-bottom: 50px;

  .digitalPlatform-title {
    margin: 30px 0;
  }

  .digitalPlatform-content {
    padding: 0 50px 50px 50px;

    .left-cont {
      width: 600px;
      padding-top: 50px;
      & > div:nth-child(3) {
        .list {
          margin-top: 15px;

          & > .dp-list-title1 {
            background: #2C913D;
            height: 40px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 40px;
          }
        }
      }
    }

  }

  .line {
    width: 100%;
    border: 1px solid rgba(153, 153, 153, 0.2)
  }

}
.dp-cont-title1 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}
.dp-cont-title2 {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
}

</style>
