<template>
  <div class="flex justify-content-center flex-warp">
    <kcTitle2 title="养猪全链精准饲喂系统" en="Whole-chain Accurate feeding System for Swine"></kcTitle2>
    <div style="width: 1000px;margin-top: 130px; margin-bottom: 80px" class="flex justify-content-center flex-warp">
      <img src="../../../assets/images/Core/img/AFSystem.png" width="100%" class="pointer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution"
}
</script>

<style scoped>

</style>
