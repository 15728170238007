<template>
  <div class="alarm">
    <div class="flex justify-content-center title">
      <kcTitle2 title="生物安全" en="Biosecurity"></kcTitle2>
    </div>
    <div style="margin-top: 60px" class=" alarm-cont flex justify-content-between">
      <div style="width: 50%" one><img src="../../../assets/images/Core/img/safety.png" width="100%">
      </div>
      <div style="width: 43%" two>
        <div title>关乎生猪健康和养殖效益，预防、控制必不可少</div>
        <div title1 class="mt20">
          自非洲猪瘟以来，生物安全问题越来越被重视，良好的生物安全措施，是猪场防控疾病的前提，也是猪场取得较好经济效益的保证。通过生物安全智能防控系统，生物安全监控解决方案，实现智能抓拍、越界行为识别、车辆检测等猪场关键环节的智能监控及预警管理，洗消烘干系统实现人员、车辆、物资等“洗－消－烘”全过程严格监测，可远程查看每天的洗消记录，确保工作流程被正确执行，解决管理难问题，切实保障生物安全。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bioSafety"
}
</script>

<style lang="scss" scoped>
.alarm {
  padding-bottom: 50px;

  .alarm-cont {
    margin: 30px 0;

    & div[one] {

    }

    & div[two] {
      padding-top: 30px;
      padding-left: 30px;

      & div[title] {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }

      & div[title1] {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
    }
  }
}
</style>
