<template>
  <div class="app-container ">
    <div v-if="show == 0">
      <div>
        <el-carousel trigger="click" :height="height + 'px'" :autoplay="false">
          <el-carousel-item>
            <img class="img" src="../../assets/images/Core/img/header-bg-img.png" width="100%" @load="imgLoad"/>
          </el-carousel-item>
          <el-carousel-item>
            <img class="img" src="../../assets/images/Core/img/platform.png" width="100%"/>
          </el-carousel-item>
          <el-carousel-item>
            <img class="img" src="../../assets/images/Core/img/hardware.png" width="100%"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="flex justify-content-center flex-warp">
        <div class="content">
          <div class="cont">
            <el-col :lg="13">
              <div class=" top-bar-menu-item flex align-items-center justify-content-around"
                   style="width: 100%;">
                <div class="top-bar-menu-item-title pointer" @click.capture="activeItem(0)"  @mouseenter="isShowChild=true"
                     @mouseleave="isShowChild=false"
                     :class="{'top-bar-menu-item-title-active': active == 0}">{{ $t('transfer.t', {t: '解决方案'}) }}
                  <transition name="el-fade-in-linear">
                    <div class="child pb10" v-if="isShowChild">
                      <div v-for="(item,index) in solutionList" :key="item"
                           class="flex flex-warp justify-content-center pt10 pb10"
                           @click="changeClick(index)">
                        <span :style="{color:index==showPage?'#333333':'#999999'}"> {{ item }}</span>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="top-bar-menu-item-title pointer" @click="activeItem(1)"
                     :class="{'top-bar-menu-item-title-active': active == 1}">{{ $t('transfer.t', {t: '数字化平台'}) }}
                </div>
                <div class="top-bar-menu-item-title pointer" @click="activeItem(2)"
                     :class="{'top-bar-menu-item-title-active': active == 2}">{{ $t('transfer.t', {t: '智能硬件'}) }}
                </div>
              </div>
            </el-col>
          </div>
          <div style="margin-top: 120px">
            <transition name="el-fade-in-linear">
              <div v-if="showPage==1 && active==0" style="width: 100%">
                <solution></solution>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div v-if="showPage==2 && active==0" style="width: 100%">
                <bioSafety></bioSafety>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div v-if="showPage==3 && active==0" style="width: 100%">
                <oldFactory></oldFactory>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div v-if="showPage==5 && active==0" style="width: 100%">
                <alarm></alarm>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div v-if="showPage==4 && active==0" style="width: 100%">
                <environmentalControl></environmentalControl>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div v-if="showPage==0 && active==0" style="width: 100%">
                <AFSystem></AFSystem>
              </div>
            </transition>

            <transition name="el-fade-in-linear">
              <div v-if="active==1" style="width: 100%">
                <digitalPlatform></digitalPlatform>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div v-if="active==2" style="width: 100%">
                <view2></view2>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="mt10">
        <bottom />
      </div>
    </div>
    <div v-if="show==1">
      <cooperationForPhone></cooperationForPhone>
    </div>
  </div>
</template>

<script>
import solution from '@/views/Core/components/solution'
import digitalPlatform from '@/views/Core/components/digitalPlatform'
import view2 from "@/views/Core/components/view2";
import alarm from '@/views/Core/components/alarm'
import bioSafety from '@/views/Core/components/bioSafety'
import bottom from '@/components/bottom'
import oldFactory from '@/views/Core/components/oldFactory'
import environmentalControl from '@/views/Core/components/environmentalControl'
import cooperationForPhone from '@/components/cooperationForPhone.vue'
import AFSystem from "./components/AFSystem";

export default {
  name: "index",
  components: {
    solution,
    digitalPlatform,
    bioSafety,
    alarm,
    oldFactory,
    environmentalControl,
    view2,
    cooperationForPhone,
    bottom,
    AFSystem
  },
  data() {
    return {
      height: 660,
      active: 0,
      show: 0,
      showPage: 0,
      solutionList: ['养猪全链精准饲喂系统', 'CCSDA数据上云一站式', '生物安全', '老场改造', 'SAE智能环控通风系统', '报警系统'],
      isShowChild: false
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let that = this;
          if (that.$route?.query?.showPage || that.$route?.query?.showPage == 0) {
            this.active = that.$route?.query?.showPage
          }
          if (this.$route?.query?.childType)
            this.showPage = this.$route?.query?.childType
        });
      },
      immediate: true
    },
  },
  mounted() {
    if (document.body.clientWidth > 640) {
      this.show = 0
    } else {
      this.show = 1
    }
    window.addEventListener("resize", () => {
      if (document.body.clientWidth > 640) {
        this.show = 0
        this.height = document.getElementsByClassName("img")[0].clientHeight;
      } else {
        this.show = 1
      }
    });
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        if (this.show == 0) {
          this.height = document.getElementsByClassName("img")[0].clientHeight;
        }
      });
    },
    // 点击导航栏方法
    activeItem(val) {
      this.showPage = 0
      this.active = val
    },
    // 点击下拉菜单方法
    changeClick(val) {
      this.active = 0
      this.showPage = val
    },
  }
}
</script>

<style lang="scss" scoped>
  .bg-img {
    transition: opacity 0.3s;
  }

  .content {
    min-width: 1200px;
    width: 1440px;
    position: relative;

    .cont {
      width: 100%;
      position: absolute;
      top: -30px;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      z-index: 3000;

      .top-bar-menu-item-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0px 20px;
        position: relative;
        height: 50px;

        .child {
          padding-left: 20px;
          position: absolute;
          top: 105%;
          background-color: #fff;
          width: 230%;
          color: #999999;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        }

        &:hover {
          color: #18933B;
        }
      }

      .top-bar-menu-item-title-active {
        font-weight: 500;
        color: #18933B;
        border-bottom: 2px solid #18933B;
      }
    }
  }
</style>
