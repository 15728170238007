<template>
  <div class="alarm">
    <div class="flex justify-content-center title">
      <kcTitle2 title="报警系统" en="Alarm system"></kcTitle2>
    </div>
    <div  style="margin-top: 60px" class=" alarm-cont flex justify-content-between">
      <div style="width: 50%" one><img src="../../../assets/images/Core/img/alarmimg.png" width="100%">
      </div>
      <div style="width: 43%" two>
        <div title>为养殖场提供全面安全的解决方案</div>
        <div title1 class="mt20">
          报警系统能够对养殖场出现的异常及时提醒，并提供相应解决方案，避免生产事故发生。针对不同养殖规模，提供不同的解决方案。现场声光报警，远程手机电话报警，物联网平台报警。同时，能够实现逐级报警，可设置多个报警联系人及电话，防止未接听报警信息造成生产事故。不同报警的数据生成告警记录，所有告警数据及解决方式都清晰显示在电脑或手机上，内置电池，用于因电源故障的紧急呼叫，安全可靠。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "alarm"
}
</script>

<style lang="scss" scoped>
.alarm {
  padding-bottom: 50px;

  .alarm-cont {
    margin: 30px 0;

    & div[one] {

    }

    & div[two] {
      padding-top: 30px;
      padding-left: 30px;
      & div[title]{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }
      & div[title1]{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
    }
  }
}
</style>
