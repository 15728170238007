<template>
  <div class="alarm">
    <div class="flex justify-content-center title">
      <kcTitle2 title="SAE智能环控通风系统" en="SAE intelligent ring control ventilation system"></kcTitle2>
    </div>
    <div style="margin-top: 60px" class=" alarm-cont flex align-items-center justify-content-between">
      <div style="width: 50%" one><img src="../../../assets/images/Core/img/SAE.gif" width="100%">
      </div>
      <div style="width: 43%" two>
        <div title>为各种条件下的健康生长创造环境，生长好，利润高</div>
        <div title1 class="mt20">
          环控的作用是为动物生长创造舒适的生长环境，预防疾病并减少药物的使用。环境控制器可精确控制养殖场的环境，通过传感器读数的数据信息，比如温度，湿度等。可根据日龄、体重等自动调整参数。在最适宜的条件和生产利润之间找到平衡，实现养殖的目标。此外，将环境控制器连接到小科爱牧O²S智慧养殖大平台云端。用户可以通过任何连接到互联网的设备检查其是否正常运行，以及手动调整参数。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "environmental"
}
</script>

<style lang="scss" scoped>
.alarm {
  padding-bottom: 50px;

  .alarm-cont {
    margin: 30px 0;

    & div[one] {

    }

    & div[two] {
      padding-top: 30px;
      padding-left: 30px;
      & div[title]{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }
      & div[title1]{
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
    }
  }
}
</style>
