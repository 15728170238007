<template>
  <div class="alarm">
    <div class="flex justify-content-center title">
      <kcTitle2 title="老场改造方案" en="Old plant renovation program"></kcTitle2>
    </div>
    <div style="margin-top: 60px" class="alarm-cont flex align-items-center justify-content-between">
      <div style="width: 50%" one>
        <img src="../../../assets/images/Core/img/factory.png" width="100%">
      </div>
      <div style="width: 43%" two>
        <div title>实现数字化系统升级，简洁、智能、方便</div>
        <div title1 class="mt20">
          针对解决目前我国一些养殖场建场时间久，环控不具备联网功能，但想实现数字化系统升级等问题，进而达到降本增效目的。通过安装数据采集器S1，实现正常采集温度、湿度、CO₂等环境信息及饮食和饮水的消耗数据；同时，通过物联网直接将数据交互到小科爱牧O²S智慧养殖大平台，通过平台实现数据的查询，展示，分析等操作。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oldFactory"
}
</script>

<style lang="scss" scoped>
.alarm {
  padding-bottom: 50px;

  .alarm-cont {
    margin: 30px 0;

    & div[one] {

    }

    & div[two] {
      padding-top: 30px;
      padding-left: 30px;

      & div[title] {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }

      & div[title1] {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
    }
  }
}
</style>
