<template>
  <div class="flex justify-content-center flex-warp">
    <kcTitle2 title="CCSDA数据上云一站式解决方案" en="SAE INTELLIGENT climate controll VENTILATION SYSTEM"></kcTitle2>
    <div style="width: 1000px;margin-top: 30px" class="flex justify-content-center flex-warp">
      <img src="../../../assets/images/Core/img/futurefarm.png" width="100%" class="pointer"/>
      <div class="font-bold" style="margin-top: 150px; margin-bottom: 120px">
        <img src="../../../assets/images/Core/img/pie.png" width="100%"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution"
}
</script>

<style scoped>

</style>
